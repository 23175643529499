import React from "react";
import { NavLink } from "react-router-dom";
import { useApi } from "../components/useApi";
import DateTimeFormat from "./DateTimeFormat";
import { Icon } from "./Icon";
import { Pagination } from "../components/Pagination";

export default function ActivityCard() {

    const [events,
        error,
        nextPage,
        prevPage,
        gotoPage,
        currentPage,
        pages,
        isLoading] = useApi(process.env.REACT_APP_API + "/events", { scanForward: false, pageSize: 10 });

    function onPageSizeClick(option) {
        gotoPage(1);
    }

    if (events) {
        return (<>
            <div className={`flex flex-col w-98 bg-white shadow-lg rounded-sm border border-gray-200 pb-3`}>
                <div className="px-5 pt-2 pl-2 pb-2 mb-1">
                    <header className="flex justify-between items-start text-xs">
                        <div><h2 className="text-lg font-semibold text-gray-800">Recent Activity</h2></div>
                    </header>
                    <div className="mt-2">{events.map((e, i) => <Event event={e} index={i} key={i}/>)}</div>
                    <Pagination pages={pages}
                                currentPage={currentPage}
                                onNextClick={nextPage}
                                onPageClick={gotoPage}
                                onPrevClick={prevPage} />
                </div>
            </div></>);
    }
    else {
        return (<><div className={`flex flex-col w-80 bg-white shadow-lg rounded-sm border border-gray-200 pb-3`}>
            <div className="px-5 pt-2 pl-2 pb-2 mb-1">
                <header className="flex justify-between items-start text-xs">
                    <div><h2 className="text-lg font-semibold text-gray-800">Recent Activity</h2></div>
                </header>
                <div className="text-gray-300 text-sm m-2">
                    Loading...
                </div>
            </div>
        </div></>);
    }
}

function Event(props) {
    const { event, index } = props;

    const isEven = index % 2 == 0;

    return (<>
        <div className={`flex flex-row flex-nowrap flex-initial text-xs font-mono font-light p-2 pl-1 align-middle ${isEven ? 'bg-gray-100' : 'bg-white'}`}>
            <div className="flex-grow-none w-42"><DateTimeFormat value={event.date} format="MMM dd, yyyy tt" /></div>
            <div className="flex-grow-none w-8 text-indigo-500 pl-2 pr-2 mr-2 ml-2"><Icon value={event.level} /></div>
            <div className="flex-grow-none w-44 overflow-ellipsis"><NavLink to={`/listing/${event.state?.listingId}`}>{event.state?.listingId}</NavLink>
                {event.state?.auctionReportId && (<><br />Report Id:{event.state?.auctionReportId}</>) || null}</div>
            <div className="flex-grow w-48 overflow-ellipsis break-all">{event.message}</div>
        </div>
    </>);
}