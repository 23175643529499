import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useParams, } from 'react-router';
import dataServices, { getDocumentProviders } from '../common/api';
import ErrorPanel from '../components/ErrorPanel';
import { useFieldArray, useForm } from 'react-hook-form';
import { DateTime } from 'luxon';
import DashboardPage from '../components/dashboard-template/DashboardPage';

export default function DocumentProviders(props) {

  const { docType } = useParams();
  const [vendors, setVendors] = useState([]);
  const [distributionData, setDistributionData] = useState([]);
  const [dateRange, setDateRange] = useState({});
  const { register, handleSubmit, control, formState, reset, watch, getValues, setError, clearErrors } = useForm();
  const { errors, isDirty } = formState;
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({ control, name: "vendors" });
  const [error, setErrorMsg] = useState(null);
  const [totalPercent, setTotalPercent] = useState(0);

  const [uiState, setUiState] = useState({ isLoading: true });


  const submit = data => {
    // Check total % allocation
    const total = checkTotal(data.vendors);
    if (total != 100) {
      const m = "Total distribution should equal 100%.";
      setErrorMsg({ message: m });
      return;
    }
    else {
      setErrorMsg(null);
    }

    var vendors = { vendors: data.vendors.map(x => { return { ...x, distribution: x.distribution / 100 } }) };
    dataServices.updateDocumentProviders(vendors)
      .then(r => {
        if (r.data.isSuccess === false) {
          setErrorMsg(r.data.error);
        }
      })
  }

  const checkTotal = (v) => {
    let total = 0;
    v.map(e => total += (isNaN(e.distribution) || !e.distribution ? 0 : parseInt(e.distribution)));
    return total;
  }

  var distributionValidation = {
    valueAsNunber: true,
    min: {
      value: 0,
      message: "Distribution value has to be greater than zero."
    },
    max: {
      value: 100,
      message: "Distribution value must be less than 100."
    }
  }

  useEffect(() => {
    var r = getDocumentProviders(docType);
    r.then(r => {
      if (r.data.isSuccess === false) {
        setErrorMsg(r.data.error);
      }
      else {
        var v = r.data.items.map(x => { return { ...x, distribution: x.distribution * 100 } });
        setVendors(v);
      }
    })
      .catch(e => {
        setErrorMsg({ message: e.message });
      });


  }, [docType]);

  useEffect(() => {

    // The current date
    var endDate = DateTime.now().toUTC().startOf('day').startOf('month');
    var startDate = endDate.minus({ months: 1 }).startOf('month');

    setDateRange({ startDate: startDate, endDate: endDate });

    // Get the vendor distribution for current month and previous month
    var dr = dataServices.getVendorDistributions(docType, startDate, endDate);

    // Check API response
    dr.then(r => {
      console.log(r.data);
      if (r.data.isSuccess === false) {
        setErrorMsg(r.data.error);
      }
      else {
        setDistributionData(r.data.items);
      }
    });

    // Set the form component
    var vs = { vendors: vendors };
    reset(vs);

    setUiState({
      isLoading: false
    });
  }, [vendors]);


  useEffect(() => {
    var v = getValues("vendors");

    if (!v) { setTotalPercent(0); }
    else {
      let total = 0;
      v.map(e => total += (isNaN(e.distribution) || !e.distribution ? 0 : parseInt(e.distribution)));
      setTotalPercent(total);
    }

  }, [watch("vendors")])

  if (uiState.isLoading === true) {
    return (<><p>"Loading..."</p></>);
  }

  return (<><DashboardPage>
    <div className="document-providers">
      <ErrorPanel error={error} />
      <div>
        <form onSubmit={handleSubmit(submit)}>
          <table className="w-3/4">
            <thead>
              <tr className="bg-gray-100 border-b-2">
                <th className="p-3"><div className="font-black text-left">Name</div></th>
                <th className="p-2"><div className="font-black text-left">Distribution %</div></th>
                <th className="p-2"><div className="font-black text-left">{dateRange?.startDate?.toFormat("yyyy-MM")}</div></th>
                <th className="p-2"><div className="font-black text-left">{dateRange?.endDate?.toFormat("yyyy-MM")}</div></th>
                <th className="p-2"><div className="font-black text-left">Vendor Total</div></th>
                <th className="p-2"><div className="font-black text-left">% Of Orders</div></th>
              </tr>
            </thead>
            {
              fields.map((field, index) => (<Line field={field} register={register} index={index} errors={errors} dateRange={dateRange} stats={distributionData} />))
            }
            <tfoot>
              <tr className="border-t-2 bg-gray-100">
                <td colSpan="1" className="p-2"><div className="font-black">Total Distribution:</div></td>
                <td colSpan="1" className="p-2">{totalPercent}%</td>
                <td colSpan="3" className="p-2">&nbsp;</td>
                <td colSpan="1" align="right" className="p-2 align-right"><button className="btnsave border-2 p-5 pt-1 pb-1 bg-blue-700 text-white" onClick={handleSubmit(submit)}>Save</button></td>
              </tr>
            </tfoot>
          </table>
        </form>
      </div>
    </div>
  </DashboardPage>
  </>)
}


function Line(props) {
  const { field, index, register, errors, dateRange, stats } = props;

  var distributionValidation = {
    min: {
      value: 0,
      message: "Distribution value has to be greater than zero."
    },
    max: {
      value: 100,
      message: "Distribution value must be less than 100."
    }
  }

  return (<>
    <tr key={field.id}>
      <td className="p-2"><NavLink to={`/vendor/${field.vendorId}`}>{field.vendorName}</NavLink></td>
      <td className="p-2">
        <input type="number" defaultValue={field.distribution} {...register(`vendors.${index}.distribution`, distributionValidation)} step="1" />
        {errors.vendors && errors.vendors[index]?.distribution && <>{errors.vendors[index]?.distribution.message}</>}
      </td>
      <Stats dateRange={dateRange} vendor={field} stats={stats}></Stats>
    </tr>
  </>);
}

function Stats(props) {

  const { vendor, dateRange, stats } = props;

  if (!dateRange || dateRange === undefined) { return null; }

  // Calculate the total selected count for vendor
  const totalForVendor = () => {
    return stats.filter(x => x.vendorId == vendor.vendorId)
      .map(x => x.selectedCount)
      .reduce((x, y) => x + y, 0);
  }

  // Calculate the total for all vendors
  const totalAllVendors = () => {
    if (!stats) { return 0; }

    return stats.map(x => x.selectedCount)
      .reduce((x, y) => x + y, 0);
  }

  const percentOfOrders = () => {
    const vendorTotal = totalAllVendors();
    if (vendorTotal == 0) {
      return '-';
    }

    return `${Math.round(totalForVendor() / totalAllVendors() * 100)}%`;
  }

  const forDate = (date) => {
    if (!stats || stats === undefined) return null;

    var str = date.toFormat('yyyy-MM-dd');
    const rec = stats.filter(x => x.vendorId === vendor.vendorId && x.periodEST.startsWith(str) === true);
    if (!rec || rec === undefined || rec.length === 0) {
      return (<td className="p-2">0</td>);
    }
    else {
      return (<>
        <td className="p-2">{rec && rec[0].selectedCount}</td>
      </>);
    }
  }

  return (<>
    {forDate(dateRange.startDate)}
    {forDate(dateRange.endDate)}
    <td className="p-2">{totalForVendor()}</td>
    <td className="p-2">{percentOfOrders()}</td>
  </>)

}