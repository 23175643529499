import React from 'react';
import Panel from './Panel';

export default function ErrorPanel(props) {
    const { error } = props;

    var content = error === null ? null : (<><p className="text-white">{error.message}</p>
        <Panel isVisible={error.stackTrace}>
            <div className="stack m-2">
                {error.stackTrace}
            </div></Panel>
    </>);

    return (<Panel isVisible={error !== null && error !== undefined}>
        <div className="error bg-red-400 align-middle p-2 align-middle mb-4 w-3/4">
            {content}
        </div>
    </Panel>)
}