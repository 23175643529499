import React from "react";
import { NavLink } from "react-router-dom";
import DashboardPage from "../components/dashboard-template/DashboardPage";
import SectionHeader from "../components/dashboard-template/SectionHeader";
import ErrorPanel from "../components/ErrorPanel";
import { useApi } from "../components/useApi";
import { Pagination, DropdownClassic } from "../components/Pagination";
import { Spinner } from "../components/Icon";

export default function Listings() {

  const dropDownOptions = [
    { id: 5, label: "5 items" },
    { id: 10, label: "10 items" },
    { id: 25, label: "25 items" },
    { id: 50, label: "50 items" },
    { id: 100, label: "100 items" }
  ]

  const [listings,
    error,
    nextPage,
    prevPage,
    gotoPage,
    currentPage,
    pages,
    isLoading] = useApi(process.env.REACT_APP_API + "/listings", { scanForward: false, pageSize: 10 });

  function onPageSizeClick(option) {
    gotoPage(1);
  }

  return (<>
    <DashboardPage>
      <SectionHeader>Listings</SectionHeader>
      <div className="position-relative float-right mb-5">
      <DropdownClassic options={dropDownOptions} onClick={onPageSizeClick} />
      </div>

      <ErrorPanel error={error} />

      {/* Table */}
      <div className="overflow-x-auto">
        <table className="table-auto w-full mb-5">
          {/* Table header */}
          <thead className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm">
            <tr>
              <th className="p-2"><div className="font-semibold text-left">Listing Id</div></th>
              <th className="p-2"><div className="font-semibold text-left">Seller</div></th>
              <th className="p-2"><div className="font-semibold text-left">Address</div></th>
              <th className="p-2"><div className="font-semibold text-left">City</div></th>
              <th className="p-2"><div className="font-semibold text-left">State</div></th>
              <th className="p-2"><div className="font-semibold text-left">Postal Code</div></th>
            </tr>
          </thead>
          {/* Table body */}
          <tbody className="text-sm font-medium divide-y divide-gray-100">
            <Spinner isSpinning={isLoading} />
            {
              isLoading ? null : listings?.map(l => <Line listing={l} />) ?? (<><Line listing={null} error={error} /></>)
            }
          </tbody>
        </table>
      </div>
      <Pagination pages={pages}
        currentPage={currentPage}
        onNextClick={nextPage}
        onPageClick={gotoPage}
        onPrevClick={prevPage} />
    </DashboardPage>
  </>);
}

function Line(props) {

  const { listing, error } = props;

  if (!listing) {
    return (<>
      {/* Row */}
      <tr>
        <td colSpan="6" className="p-2">
          <div className="flex items-center">
            <div className="text-gray-800">
              {
                error ? "Error Loading listings..." : "No listings"
              }
            </div>
          </div>
        </td>
      </tr>    </>);
  }

  const { seller } = listing;

  const p1 = listing?.properties[0];

  return (<>
    {/* Row */}
    <tr>
      <td className="p-2">
        <div className="flex items-center">
          <div className="text-gray-800"><NavLink className="cursor-pointer text-blue-500 hover:text-blue-900 text-underline" to={`/listing/${listing.id}`}>{listing.id}</NavLink></div>
        </div>
      </td>
      <td className="p-2">
        <div className="">{seller.legalName}</div>
      </td>
      <td className="p-2">
        <div className="">{p1?.address}</div>
      </td>
      <td className="p-2">
        <div className="">{p1?.city}</div>
      </td>
      <td className="p-2">
        <div className="">{p1?.state}</div>
      </td>
      <td className="p-2">
        <div className="">{p1?.postalCode}</div>
      </td>
    </tr>
    {
      listing.properties.map((p, i) => {
        return i === 0 ? null : <LineProp listing={listing} property={p} />;
      })
    }
  </>);
}

function LineProp(props) {
  const { property } = props;

  return (<>
    <tr>
      <td colSpan="2">&nbsp;</td>
      <td className="p-2 text-gray-500">{property?.address}</td>
      <td className="p-2 text-gray-500">{property?.city}</td>
      <td className="p-2 text-gray-500">{property?.state}</td>
      <td className="p-2 text-gray-500">{property?.postalCode}</td>
    </tr>
  </>);
}