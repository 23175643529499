import React from 'react';
import { useState } from 'react';
import dataServices from '../common/api';

import { ListingDetail } from '../components/listingDetail';

export default function ToolsPage() {
    
    const [listingId, setListingId] = useState(0);
    const [listingDetail, setListingDetail] = useState(null);

    const loadListing = () => {
        
        var response = dataServices.getListingDetail(listingId);
        response.then(r => {
            setListingDetail(r.data.item);
        }).catch(e => {
            console.log(e);
        }).finally(() => {
        });
        return false;
    }

    const listingIdChanged = (e) => {
        setListingId(e.target.value);
    }

    return (<>
        <div>Tools Page</div>

        Listing Id: 
        <input type="text" value={listingId} onChange={listingIdChanged}></input>
        <button onClick={loadListing}>Test</button>
        <br/>
        <ListingDetail listingDetail={listingDetail} />
        </>)
}