import React from 'react';
import { DocumentLine } from '../components/documentLine';

export function ListingDetail(props) {
    const { listingDetail } = props;

    if (listingDetail === null) {
        return null;
    }
    else {
        return (<>ListingId: {listingDetail.listingId}
            {
            listingDetail.documents.map(doc => <DocumentLine />)
            }</>)
    }
}

export default ListingDetail;