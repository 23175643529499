import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import dataServices from '../common/api';
import DashboardPage from '../components/dashboard-template/DashboardPage';
import SectionHeader from '../components/dashboard-template/SectionHeader';

export default function VendorsPage() {

  const [vendors, setVendors] = useState(null);
  const [query, setQuery] = useState({});

  useEffect(() => {

    var r = dataServices.getVendors(query);
    r.then(r => {
      if (r.data.isSuccess === true) {
        setVendors(r.data.item.items);
        setQuery(r.data.item);
      }
    });

  }, []);

  return (<>
    <DashboardPage>
      <SectionHeader>Vendors</SectionHeader>
      <div className="overflow-x-auto">
        <table className="table-auto w-full mb-5">
          {/* Table header */}
          <thead className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm">
            <tr>
              <th className="p-2"><div className="font-semibold text-left">ID</div></th>
              <th className="p-2"><div className="font-semibold text-left">Vendor Name</div></th>
              <th className="p-2"><div className="font-semibold text-left">Contact Name</div></th>
              <th className="p-2"><div className="font-semibold text-left">Contact Email</div></th>
              <th className="p-2"><div className="font-semibold text-left">Send Orders</div></th>
            </tr>
          </thead>
          <tbody className="text-sm font-medium divide-y divide-gray-100">
            {
              vendors ? vendors.map(m => (<VendorLine key={m.id} vendor={m} />)) : null
            }
          </tbody>
          
        </table>
      </div>
    </DashboardPage>
  </>);
}

function VendorLine(props) {

  const { vendor } = props;

  return (<>
    <tr key={vendor.id}>
      <td className="p-2 text-xs"><NavLink className="text-blue-500 hover:text-blue-900" to={`/vendor/${vendor.id}`}>{vendor.id}</NavLink></td>
      <td className="p-2 text-gray-600">{vendor.name}</td>
      <td className="p-2 text-gray-600">{vendor.contactName}</td>
      <td className="p-2 text-gray-600">{vendor.email}</td>
      <td className="p-2 text-gray-600">{vendor.isEnabled === true ? "Yes" : "No"}</td>
    </tr>
  </>)

}