import React from 'react';

function SectionHeader(props) {
    const { size, className } = props;
    
    return (<>
        <div className={`${className} font-black text-${size}xl mb-5`}>{props.children}</div>
    </>);
}

export default SectionHeader