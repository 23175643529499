import React, { useEffect, useRef, useState } from "react";
import dataServices from "../common/api";
import DashboardPage from "../components/dashboard-template/DashboardPage";
import SectionHeader from "../components/dashboard-template/SectionHeader";
import { useForm } from 'react-hook-form';

export default function ConfigPage(props) {

    const top = useRef();

    const { register, handleSubmit } = useForm();

    //TODO: Add ErrorPanel
    const [error, setErrorMsg] = useState(null);
    const [appConfigs, setAppConfigs] = useState([]);    

    const submit = data => {

        if(data.appConfigs.pendingCancellationWaitInSeconds === '') {
            data.appConfigs.pendingCancellationWaitInSeconds = null;
        }

        dataServices.updateAppconfigurations(data.appConfigs)
             .then(res => {
                if (res.data) {
                    setAppConfigs(res.data);
                }
             })
             .catch( e => {
                 if (e.response?.data) {
                     setErrorMsg(e.response.data.error);
                 } else {
                    setErrorMsg(e.response || e.message);
                }
             });
    };

    useEffect(() => {
        const resp = dataServices.getAppConfigurations();
        resp.then(res => {
          if (res.status !== 200) {
            setErrorMsg(res.statusText);
          } else {
            const v = res.data
            setAppConfigs(v);
          }
        })
          .catch(e => {
            setErrorMsg({ message: e.message });
          });
    }, []);

    return (
        <DashboardPage>
            <div ref={top}>
                <SectionHeader size="3">App Configuration Settings</SectionHeader>
            </div>

            <form onSubmit={handleSubmit(submit)}>
                <table className="w-2/4">
                    <thead>
                        <tr className="bg-gray-100 border-b-2">
                        <th className="p-3"><div className="font-black text-left">Config Name</div></th>
                        <th className="p-2"><div className="font-black text-left">Config Value</div></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="p-2">Report Cancellation Wait Time in Seconds</td>
                            
                            <td className="p-2">
                                <input type="number"
                                    defaultValue={appConfigs.pendingCancellationWaitInSeconds}
                                    {...register(`appConfigs.pendingCancellationWaitInSeconds`)}
                                    step="1"/>
                            </td>
                        </tr>
                    </tbody>
                        
                    <tfoot>
                        <tr className="border-t-2 bg-gray-100">
                        <td colSpan="1" className="p-2"><div className="font-black">&nbsp;</div></td>
                        <td colSpan="1" align="right" className="p-2 align-right"><button className="btnsave border-2 p-5 pt-1 pb-1 bg-blue-700 text-white" onClick={handleSubmit(submit)}>Save</button></td>
                        </tr>
                    </tfoot>
                </table>
            </form>
        </DashboardPage>
    )
}