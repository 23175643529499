import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import dataServices from '../common/api';
import { useFieldArray, useForm } from 'react-hook-form';
import ErrorPanel from '../components/ErrorPanel';
import DashboardPage from '../components/dashboard-template/DashboardPage';
import SectionHeader from '../components/dashboard-template/SectionHeader';
import axios from 'axios';


export default function VendorPage(props) {

    const { id } = useParams();
    const theForm = useForm();
    const [vendor, setVendor] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [error, setErrorMsg] = useState(null);

    const submit = data => {
        console.log(data);
        
        setErrorMsg(null);

        axios.post(process.env.REACT_APP_API + `/vendor`, { "vendor": data })
             .then(r => {
                if (r.data.isSuccess === true) {
                    setVendor(r.data.item);
                }
             })
             .catch( e => {
                 if (e.response?.data) {
                     setErrorMsg(e.response.data.error);
                 }
                 else {
                    setErrorMsg(e.response || e.message);
                }
             });
    }

    const maxLength = {
        value: 100,
        message: "Maximum length of 100"
    };

    const required = {
        value: true,
        message: "Required"
    };

    const requiredStringField = {
        required: required,
        maxLength: maxLength
    }

    useEffect(() => {
        var r = dataServices.getVendor(id);

        r.then(r => {
            if (r.data.isSuccess === true) {
                setVendor(r.data.item);
            }
        })
        .catch(e => {
            setErrorMsg(e.response);
        })
    }, [id]);

    useEffect(() => {
        if (!theForm) return;
        if (!vendor) return;

        theForm.reset(vendor);
        setShowForm(true);
    }, [vendor]);

    return (<>
        <DashboardPage>
            <SectionHeader>Vendor: {vendor?.name}</SectionHeader>
            <div className="form vendor">
                <ErrorPanel error={error} />

                {showForm ? (<form onSubmit={theForm.handleSubmit(submit)} defaultValues={vendor}>
                    ID: {vendor?.id}


                    <TextInput className="mt-4" theForm={theForm} name="name" label="Vendor Name" validation={requiredStringField} width="3/4"/>

                    <div className="flex gap-5 grid-cols-2 mt-4">
                        <div className="basis-3/4"><TextInput theForm={theForm} name="contactName" label="Contact Name" validation={requiredStringField} width="1/4" /></div>
                        <div><TextInput theForm={theForm} name="email" label="E-mail" validation={requiredStringField} /></div>
                    </div>
                    
                    <TextInput className="mt-4 w-3/4" theForm={theForm} name="apiServiceUrl" label="API Service Url" />

                    <ToggleSwitch className="mt-4" theForm={theForm} name="useOAuth" label="Use OAuth" />
                    <div className="flex gap-5 mt-2">
                        <div><TextInput width="full" theForm={theForm} name="clientId" label="Client Id" /></div>
                        <div><TextInput theForm={theForm} name="clientSecret" label="Client Secret" /></div>
                    </div>
                    
                    <TextInput className="mt-4" width="3/4" theForm={theForm} name="apiToken" label="API Token" />
                    
                    
                    <ToggleSwitch className="mt-4 mb-2" theForm={theForm} name="isEnabled" label="Enabled" />

                    <div><input type="submit" className="form-input border-2 p-2 m-2 ml-0 mt-4" /></div>

                </form>) : null}
            </div>
        </DashboardPage>
    </>);
}

function InputError(props) {
    const { errorMsg } = props;

    return (!errorMsg ? null : (<><div className="text-xs mt-1 text-red-500">{errorMsg}</div></>));
}

function TextInput(props) {

    const { theForm, name: id, label, validation, width, className } = props;
    const { formState, register } = theForm;
    const { errors } = formState;

    const isError = errors[id]?.message;
    const asterisk = isError ? (<><span className="text-red-500">*</span></>) : null;

    return (<>{/* Start */}
            <div className={className}>
                <label className="block text-sm font-medium mb-1" htmlFor={id}>{label}{asterisk}</label>
                <input id={id} className={`form-input w-${width || 'full'}`} type="text" {...register(id, validation)} />
            <InputError errorMsg={errors[id]?.message} />
            </div>
        {/* End */}</>);
}

function ToggleSwitch(props) {

    const { theForm, name: id, label, validation } = props;
    const { formState, register, control } = theForm;
    const { errors } = formState;

    const current = control?.defaultValuesRef?.current;
    const defVal = current ? current[id] : null;

    const isError = errors[id]?.message;
    const asterisk = isError ? (<><span className="text-red-500">*</span></>) : null;

    return (<>
        <div className={`flex items-center ${props.className || ''} `}>
            <div className='mr-2'>{label} {asterisk}</div>
            <div className="form-switch focus-within:outline-blue">
                <input type="checkbox" id={id} className="sr-only" {...register(id, validation)} />
                <label className="bg-gray-400" htmlFor={id}>
                    <span className="bg-white shadow-sm" aria-hidden="true"></span>
                    <span className="sr-only">{label}</span>
                </label>
            </div>
            <div className="text-sm text-gray-400 italic ml-2">{defVal ? 'On' : 'Off'}</div>
        </div>
    </>)
}
