import React, { useEffect, useRef } from "react";
import dataServices from "../common/api";
import DashboardPage from "../components/dashboard-template/DashboardPage";
import SectionHeader from "../components/dashboard-template/SectionHeader";
import DateTimeFormat from "../components/DateTimeFormat";
import Flex from "../components/Flex";
import Panel from "../components/Panel"
import { Pagination, DropdownClassic } from "../components/Pagination";
import { useApi } from "../components/useApi";

const dropDownOptions = [
    { id: 5, label: "5 items" },
    { id: 10, label: "10 items" },
    { id: 25, label: "25 items" },
    { id: 50, label: "50 items" },
    { id: 100, label: "100 items" }
  ]


export default function HttpLogsPage(props) {

    const [logLines,
        error,
        nextPage,
        prevPage,
        gotoPage,
        currentPage,
        pages,
        isLoading] = useApi(process.env.REACT_APP_API + `/logs/http`, { scanForward: false, pageSize: 10 });

    const top = useRef();

    const onPageSizeClick = (p) => {
        gotoPage(p);
    }

    useEffect(() => {
       top.current.scrollIntoView({ behavior: "smooth" });
    }, [ logLines ]);

    return (<DashboardPage>
        <div ref={top}>
            <SectionHeader size="3">HTTP Logs</SectionHeader>
            <DropdownClassic options={dropDownOptions} onClick={onPageSizeClick} />
        </div>
        <LogLines logs={logLines} />
        <Pagination pages={pages}
            currentPage={currentPage}
            onNextClick={nextPage}
            onPageClick={gotoPage}
            onPrevClick={prevPage} />
       
    </DashboardPage>)
}

function LogLines(props) {
    const { logs } = props;

    if (logs) {
        return (<div>
            {logs.map((l, i) => <LogLine line={l} index={i} />)}
        </div>)
    }

    return null;
}

function LogLine(props) {
    const { line, index } = props;

    const isEven = index % 2 == 0;
    const className = `${isEven ? 'bg-gray-100' : 'bg-white'} font-mono text-sm`
    return (<>
        <Panel isVisible={!!line}>
            <div className={` ${className} mt-1 p-3`}>
                <Flex className="font-bold">
                    <div className="w-48 align-middle"><DateTimeFormat value={line.date} format="yyyy/MM/dd tt" /></div>
                    <div className="w-10">{line.method}</div>
                    <div className="w-24">{line.path}</div>
                    <div className="ml-4 w-6">{line.errorCode}</div>
                </Flex>
                <div className="overflow-ellipsis w-94 ml-48">{line.body}</div>
            </div>
        </Panel>
    </>);
}

