import React from 'react';

export function Panel(props) {

    const { isVisible } = props;

    if (isVisible) {
        return <>{props.children}</>
    }
    else {
        return null;
    }
}

export default Panel;