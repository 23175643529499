import React from "react";

function SectionPanel(props) {

    const { visible } = props;

    return visible === false ? null :
    (<>
     {props.children}
     </>);
}

export default SectionPanel;