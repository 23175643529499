import axios from "axios"

export const get = (path, config) => {
    return axios.get(process.env.REACT_APP_API + path, config);
}

export const getDocumentProviders = (docType, config) => {
    return get(`/document-providers?docType=${docType}`, config);
}

const dataServices = {
    updateDocumentProviders: (vendors) => {
        return axios.post(process.env.REACT_APP_API + "/document-providers/distribution", vendors);
    },
    getVendorDistributions: (docType, startDate, endDate) => {
        var startStr = startDate.toLocaleString();
        var endStr = endDate.toLocaleString();

        console.log(`/document-providers/stats?docType=${docType}&startDate=${encodeURIComponent(startStr)}&endDate=${encodeURIComponent(endStr)}`);
        return get(`/document-providers/stats?docType=${docType}&startDate=${encodeURIComponent(startStr)}&endDate=${encodeURIComponent(endStr)}`);
    },
    getVendors: (request) => {
        return axios.post(process.env.REACT_APP_API + "/vendors", request);
    },
    getVendor: (id, config) => {
        return get(`/vendor/${id}`, config);
    },

    getListings: (r) => {
        return axios.post(process.env.REACT_APP_API + "/listings", r);
    },

    getListing: (id) => {
        return get('')
    },

    getListingDetail: (id, config) => {
        return get(`/listing/${id}`, config);
    },

    getListingStepHistory: (id, req) => {
        return axios.post(process.env.REACT_APP_API + `/listing/${id}/step-history`, req)
    },

    getHttpLogs: (request) => {
        return axios.post(process.env.REACT_APP_API + `/logs/http`, request);
    },

    getAppConfigurations: (config) => {
        return axios.get(process.env.REACT_APP_API + `/config/setting`, config);
    },

    updateAppconfigurations: (data, config) => {
        return axios.post(process.env.REACT_APP_API + `/config/setting`, data, config);
    }
}

export default dataServices;