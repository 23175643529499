import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dataServices from "../common/api";
import { CONTACT_ROLE, DOC_TYPE, REPORT_STATUS, REPORT_STATUS_LABEL } from "../common/enums";
import DashboardPage from "../components/dashboard-template/DashboardPage";
import SectionHeader from "../components/dashboard-template/SectionHeader";
import SectionPanel from "../components/dashboard-template/SectionPanel";
import Panel from "../components/Panel";
import Flex from "../components/Flex";
import { Icon } from "../components/Icon";
import { useApi } from "../components/useApi";
import { Pagination, DropdownClassic } from "../components/Pagination";
import { Spinner } from "react-bootstrap";
import ErrorPanel from "../components/ErrorPanel";

function groupBy(xs, key) { return xs.reduce(function (rv, x) { let v = key instanceof Function ? key(x) : x[key]; let el = rv.find((r) => r && r.key === v); if (el) { el.values.push(x); } else { rv.push({ key: v, values: [x] }); } return rv; }, []); }


const TABS = Object.freeze({
    Reports: 0,
    Contacts: 1,
    History: 2
});

const PROCESSSTATUS = Object.freeze({
    0: "Unknown",
    1: "New",
    2: "Listing Incomplete",
    3: "Processing",
    4: "Error",
    5: "Completed"
});

export default function ListingDetailPage(props) {

    const { id } = useParams();
    const [listing, setListing] = useState(null);
    const [reports, setReports] = useState(null);
    const [tab, setTab] = useState(TABS.Reports);
    const [properties, setProperties] = useState(null);

    useEffect(() => {
        var r = dataServices.getListingDetail(id);
        r.then(r => {

            if (r.data.isSuccess === true) {
                setListing(r.data.item);

                var l = r.data.item;
                if (l) {
                    var reports = l.reports.map(r => ({
                        ...r,
                        property: l.properties.find(p => p.id === r.propertyId)
                    }));
                    setReports(reports);

                    var properties = l.properties.map(p => ({
                        ...p,
                        reports: l.reports.filter(a => a.propertyId === p.id)
                                          .sort((a,b) => { 
                                              if (a < b) { return -1; }
                                              if (a > b) { return 1; }
                                              return 0;
                                          })
                    }));
                    setProperties(properties);
                }
            }
        });
    }, [id]);

    return (<>
        <DashboardPage>
            <SectionHeader size="2">Listing {listing?.id} </SectionHeader>
            <SectionHeader className="mt-1" size="1"><ListingStatus listing={listing} /></SectionHeader>

            <ListingValidationErrors id={id} />

            <Flex className="mb-5">
                <SellerCard seller={listing?.seller} />
                <TitleCard title={listing?.titleCompany} className="ml-5" />
                <EscrowCard escrow={listing?.escrowCompany} className="ml-5" />
            </Flex>

            <div>
                {/* Start */}
                <div className="relative mb-8">
                    <div className="absolute bottom-0 w-full h-px bg-gray-200" aria-hidden="true"></div>
                    <ul className="relative text-sm font-medium flex flex-nowrap -mx-4 sm:-mx-6 lg:-mx-8 no-scrollbar">
                        <Tab setTab={setTab} tab={TABS.Reports} selectedTab={tab}>Reports</Tab>
                        <Tab setTab={setTab} tab={TABS.Contacts} selectedTab={tab}>Contacts</Tab>
                        <Tab setTab={setTab} tab={TABS.History} selectedTab={tab}>History</Tab>
                    </ul>
                </div>
                {/* End */}
            </div>


            <SectionPanel visible={tab === TABS.Reports}>
                <Properties properties={properties} />
            </SectionPanel>

            <SectionPanel visible={tab === TABS.Contacts}>
                <SectionHeader size="1">Contacts</SectionHeader>
                <Contacts contacts={listing?.contacts} />
            </SectionPanel>

            <SectionPanel visible={tab === TABS.History}>
                <SectionHeader size="1">Log History</SectionHeader>
                <HistoryPanel id={id} />
            </SectionPanel>
        </DashboardPage>

    </>);
}

function Contacts(props) {
    const { contacts } = props;

    return contacts &&
        (<>
            <div className="overflow-x-auto">
                <table className="table-auto w-full mb-5">
                    {/* Table header */}
                    <thead className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm">
                        <tr>
                            <th className="p-2"><div className="font-semibold text-left">ID</div></th>
                            <th className="p-2"><div className="font-semibold text-left">Contact Name</div></th>
                            <th className="p-2"><div className="font-semibold text-left">Role</div></th>
                            <th className="p-2"><div className="font-semibold text-left">Email</div></th>
                            <th className="p-2"><div className="font-semibold text-left">Phone</div></th>
                        </tr>
                    </thead>
                    {/* Table body */}
                    <tbody className="text-sm font-medium divide-y divide-gray-100">
                        {
                            contacts.map(c => <Contact contact={c} />)
                        }
                    </tbody>
                </table>
            </div></>);
}

function Reports(props) {

    const { reports } = props;

    return reports &&
        (<>
            {/* Report table */}
            <div className="overflow-x-auto">
                <table className="table-auto w-full mb-5">
                    {/* Table header */}
                    <thead className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm">
                        <tr>
                            <th className="p-2"><div className="font-semibold text-left">Report Type</div></th>
                            <th className="p-2"><div className="font-semibold text-left">Property Name</div></th>
                            <th className="p-2"><div className="font-semibold text-left">Property Address</div></th>
                            <th className="p-2"><div className="font-semibold text-left">Document Status</div></th>
                            <th className="p-2"><div className="font-semibold text-left">Contact Name</div></th>
                            <th className="p-2"><div className="font-semibold text-left">Contact Phone</div></th>
                            <th className="p-2"><div className="font-semibold text-left">Contact Email</div></th>
                            <th className="p-2"><div className="font-semibold text-left">Document Cost</div></th>
                            <th className="p-2"><div className="font-semibold text-left">Est. Delivery Date</div></th>
                            <th className="p-2"><div className="font-semibold text-left">Date Delivered</div></th>
                            <th className="p-2"><div className="font-semibold text-left">Document Url</div></th>
                            <th className="p-2"><div className="font-semibold text-left">Dataroom Id</div></th>
                        </tr>
                    </thead>
                    {/* Table body */}
                    <tbody className="text-sm font-medium divide-y divide-gray-100">
                        {
                            reports.map(r => (<Report report={r} />))
                        }
                    </tbody>
                </table>
            </div></>);
}

function Report(props) {

    const { report } = props;



    function formatDate(dt) {
        if (!dt) return "";

        var _local = DateTime.fromISO(dt);
        return _local.toLocaleString();
    }

    return report &&
        (<>
            <tr>
                <td className="p-2">{DOC_TYPE[report.documentType]}</td>
                <td className="p-2">{report.property?.name}</td>
                <td className="p-2">{report.property?.address}</td>
                <td className="p-2">{REPORT_STATUS[report.orderStatus]}</td>
                <td className="p-2">{report.vendorContact?.name}</td>
                <td className="p-2">{report.vendorContact?.phone}</td>
                <td className="p-2">{report.vendorContact?.email}</td>
                <td className="p-2">{report.price}</td>
                <td className="p-2">{formatDate(report.estimatedDeliveryDate)}</td>
                <td className="p-2">{formatDate(report.dateReceived)}</td>
                <td className="p-2">{report.documentUrl}</td>
                <td className="p-2">{report.dataRoomId}</td>
            </tr>
        </>);
}

function Contact(props) {
    const { contact } = props;

    return (<>
        <tr>
            <td className="p-2">{contact.id}</td>
            <td className="p-2">{contact.name}</td>
            <td className="p-2">{CONTACT_ROLE[contact.role]}</td>
            <td className="p-2">{contact.email}</td>
            <td className="p-2">{contact.phone}</td>
        </tr>
    </>);
}

function Tab(props) {

    const { setTab, tab, selectedTab } = props;

    return (<li className="mr-6 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8">
        <TabLink setTab={setTab} tab={tab} selectedTab={selectedTab}>{props.children}</TabLink></li>);
}

function TabLink(props) {
    const { tab, selectedTab, setTab } = props;

    const selectedClass = selectedTab === tab ? "border-b-2 border-indigo-500" : null;

    return (<a onClick={() => setTab(tab)} className={`block pb-3 text-indigo-500 whitespace-nowrap ${selectedClass} `}>{props.children}</a>);
}

function ListingValidationErrors(props) {

    const { id } = props;
    const [step, setStep] = useState();

    useEffect(() => {

        var r = dataServices.getListingStepHistory(id, { step: "UpdateListing" });
        r.then(r => {
            if (r.data.isSuccess === true) {
                if (r.data.items.length > 0) {
                    setStep(r.data.items[0]);
                }
                else {

                    r = dataServices.getListingStepHistory(id, { step: "ValidateListing" });
                    r.then(r => {
                        if (r.data.isSuccess === true) {
                            if (r.data.items.length > 0) {
                                setStep(r.data.items[0]);
                            }
                        }
                        else {
                            setStep(null);
                        }
                    })
                }
            }
        })
    }, []);

    return <ValidationPanel validationErrors={step?.validation} />

}

function ValidationPanel(props) {
    const { validationErrors } = props;
    if (!validationErrors || validationErrors.length === 0) return null;

    const errorContent = validationErrors &&
        validationErrors.map(e => (<><ValidationErrorLine validationError={e} /></>)) || null;

    return (<Panel isVisible={validationErrors}>
        <div className="error bg-red-400 align-middle p-2 align-middle mb-4 w-3/4">
            {errorContent}
        </div>
    </Panel>)
}

function ValidationErrorLine({ validationError }) {
    if (!validationError) return null;

    const msgList = validationError?.messages?.map(m => <li className="text-white ml-5">{m}</li>);

    const o = (<>
        <div className="text-white">{validationError.object}</div>
        <div>
            {
                validationError?.messages &&
                <ul className="list-disc">
                    {msgList}
                </ul>
            }
        </div>
    </>)

    return o
}

function HistoryPanel(props) {

    const { id } = props;

    const [logItems,
        error,
        nextPage,
        prevPage,
        gotoPage,
        currentPage,
        pages,
        isLoading] = useApi(process.env.REACT_APP_API + `/listing/${id}/log`, { scanForward: false, pageSize: 50 });

    if (isLoading) {
        return <Spinner isLoading={isLoading} />;
    }

    return (<>
        <ErrorPanel error={error} />
        {logItems && logItems.map((l, i) => <LogLine log={l} index={i} />)}
        <Pagination pages={pages}
                    currentPage={currentPage}
                    onNextClick={nextPage}
                    onPageClick={gotoPage}
                    onPrevClick={prevPage}  />
    </>)
}

function LogLine(props) {
    const { log, index } = props;

    const dt = DateTime.fromISO(log.date);
    const isEven = index % 2 === 0;

    return (<>
        <div className={`flex flex-row flex-nowrap flex-initial text-xs font-mono font-light p-2 pl-1 align-middle ${isEven ? 'bg-gray-100' : 'bg-white'}`}>
            <div className="flex-grow-none w-42">{dt.toFormat('yyyy MMM dd hh:mm:ss')}</div>
            <div className="flex-grow-none w-8 text-indigo-500 pl-2 pr-2 mr-2 ml-2"><Icon value={log.level} /></div>
            <div className="flex-grow-none w-44 overflow-ellipsis">{log.context}
                {log.state?.auctionReportId && (<><br />Report Id:{log.state?.auctionReportId}</>) || null}</div>
            <div className="flex-grow w-48 overflow-ellipsis break-all">{log.message}</div>
        </div>
    </>);
}



function PropertyCard(props) {
    const { property } = props;

    let  unitOrLot  = property.reports[0].unitOrLot;
    if (unitOrLot) {
        unitOrLot = "(Unit: " + unitOrLot + ")";
    }

    const rpts = property.reports.sort((x, y) => x.documentType - y.documentType);
    return (<>
        <div className="mt-4">
            <SectionHeader>
                <div>{property?.address}, {property?.city}, {property?.state} {property.postalCode} {unitOrLot}</div>
                <div className="text-xs text-gray-300 -mt-half">Prop ID: {property?.id} { unitOrLot ? ` | Unit Id: ${property.reports[0].unitOrLotId}` : null }</div>
            </SectionHeader>

            <div className="flex flex-row">
                {rpts && rpts.length > 0 && rpts.map((r, i) => (<AuctionReportCard report={r} index={i} />))}
            </div></div></>);
}

function SellerCard(props) {
    const { seller } = props;

    if (seller) {
        return (<>
            <div className={`flex flex-col w-80 bg-white shadow-lg rounded-sm border border-gray-200 pb-3}`}>
                <div className="px-5 pt-2 pl-2 pb-2 mb-1">
                    <header className="flex justify-between items-start text-xs">
                        <div><h2 className="text-lg font-semibold text-gray-800">Seller</h2></div>
                        <div className="text-gray-300">
                            {seller.locationId}
                        </div>
                    </header>
                    <div>{seller.legalName}</div>
                    <div>{seller.email}</div>
                </div>
            </div></>);
    }
    else {
        return (<>
            <div className={`flex flex-col w-80 bg-white shadow-lg rounded-sm border border-gray-200 pb-3}`}>
                <div className="px-5 pt-2 pl-2 pb-2 mb-1">
                    <header className="flex justify-between items-start text-xs">
                        <div><h2 className="text-lg font-semibold text-gray-800">Seller</h2></div>
                    </header>
                    <div className="text-gray-300 text-sm m-2">
                        Seller information is missing.
                    </div>
                </div>
            </div></>);
    }
}

function EscrowCard(props) {
    const { escrow, className } = props;
    if (escrow) {
        return (<>
            <div className={`flex flex-col w-80 bg-white shadow-lg rounded-sm border border-gray-200 pb-3 ${className}`}>
                <div className="px-5 pt-2 pl-2 pb-2 mb-1">
                    <header className="flex justify-between items-start text-xs">
                        <div><h2 className="text-lg font-semibold text-gray-800">Escrow Company</h2></div>
                        <div className="text-gray-300">
                            {escrow?.id}
                        </div>
                    </header>
                    <div>{escrow?.name}</div>
                    <div className="text-sm mt-2">{escrow?.contactName}</div>
                    <div className="text-sm">{escrow?.email}</div>
                </div>
            </div></>);
    }
    else {
        return (<><div className={`flex flex-col w-80 bg-white shadow-lg rounded-sm border border-gray-200 pb-3 ${className}`}>
            <div className="px-5 pt-2 pl-2 pb-2 mb-1">
                <header className="flex justify-between items-start text-xs">
                    <div><h2 className="text-lg font-semibold text-gray-800">Escrow Company</h2></div>
                    <Panel isVisible={!escrow}><div><Icon value="4" color="red" /></div></Panel>
                </header>
                <div className="text-gray-300 text-sm m-2">
                    Escrow company has not been assigned.
                </div>
            </div>
        </div></>);
    }
}

function TitleCard(props) {
    const { title, className } = props;

    if (title) {
        return (<>
            <div className={`flex flex-col w-80 bg-white shadow-lg rounded-sm border border-gray-200 pb-3 ${className}`}>
                <div className="px-5 pt-2 pl-2 pb-2 mb-1">
                    <header className="flex justify-between items-start text-xs">
                        <div><h2 className="text-lg font-semibold text-gray-800">Title Company</h2></div>
                        <div className="text-gray-300">
                            {title?.id}
                        </div>
                    </header>
                    <div>{title?.name}</div>
                    <div className="text-sm mt-2">{title?.contactName}</div>
                    <div className="text-sm">{title?.email}</div>
                </div>
            </div></>);
    }
    else {
        return (<><div className={`flex flex-col w-80 bg-white shadow-lg rounded-sm border border-gray-200 pb-3 ${className}`}>
            <div className="px-5 pt-2 pl-2 pb-2 mb-1">
                <header className="flex justify-between items-start text-xs">
                    <div><h2 className="text-lg font-semibold text-gray-800">Title Company</h2></div>
                    <Panel isVisible={!title}><div><Icon value="4" color="red" /></div></Panel>
                </header>
                <div className="text-gray-300 text-sm m-2">
                    Title company has not been assigned.
                </div>
            </div>
        </div></>);
    }
}

function AuctionReportCard(props) {
    const { report, index } = props;

    return (<>
        <div className={`flex flex-col w-80 bg-white shadow-lg rounded-sm border border-gray-200 pb-3 ${index === 0 ? '' : 'ml-5'}`}>
            <div className="px-5 pt-2 pl-2">
                <header className="flex justify-between items-start text-xs">
                    <div><h2 className="text-lg font-semibold text-gray-800">{DOC_TYPE[report.documentType]}</h2>
                        <span className="text-xs text-gray-300">{report.id}</span></div>
                    <div>
                        {report.price &&
                            (<div className="flex items-start">
                                <div className="text-1xl font-bold text-gray-800 mr-2">{new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD'
                                }).format(report.price) ?? "N/A"}</div>
                            </div>) ||
                            (<div className="flex items-start mt-1">
                                <div className="text-xs font-bold text-gray-500 mr-2">{report.price ?? "Price not available"}</div>
                            </div>)
                        }
                    </div>
                </header>
                <Tracker status={report.orderStatus} />


                <div className="text-xs " styles="font-size:.5rem">Due by {report.estimatedDeliveryDate && DateTime.fromISO(report.estimatedDeliveryDate).toFormat("MMM d, yyyy") || '  n/a'}</div>
                <VendorContact vendorContact={report?.vendorContact} />
            </div>
        </div></>);
}

function VendorContact(props) {
    const { vendorContact } = props;

    var email = (vendorContact?.email && `(${vendorContact.email})`) || '';

    if (!vendorContact?.name && !vendorContact?.email) {
        return <div className="text-xs text-gray-300 mt-2">A vendor contact has not been assigned.</div>;
    }
    else {
        return (vendorContact && (<>
            <div className="text-xs mt-2">{vendorContact?.name && `${vendorContact.name} ${email}`}</div>
        </>) || null)
    }

}


function Properties(props) {
    const { properties } = props;

    if (!properties) return null;

    // Break out by units
    const _properties = [];
    properties.map((p) => {
        var unitOrLots = groupBy(p.reports, "unitOrLot");
        unitOrLots.forEach(u => {
            _properties.push({
                ...p,
                reports: u.values
            });
        });
    });

    return (properties &&
        _properties.map(p => (<PropertyCard property={p} />))) || null;
}

function Tracker(props) {
    const { status } = props;

    const percent = {
        0: "0%",
        1: "5%",
        2: "10%",
        3: "30%",
        4: "50%",
        5: "80%",
        6: "50%",
        7: "50%",
        8: "100%",
        9: "100%",
        10: "80%",
        11: "100%"
    }

    return (<><div className="relative pt-1">
        <div className="flex mb-2 items-center justify-between">
            <div>
                <span className="text-xs font-medium inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200" style={{ fontSize: ".5rem" }}>
                    {REPORT_STATUS_LABEL[status]}
                </span>
            </div>
            <div className="text-right">
                <span className="text-xs font-semibold inline-block text-pink-600">
                    {percent[status]}
                </span>
            </div>
        </div>
        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-pink-200">
            <div style={{ width: percent[status] }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-pink-500"></div>
        </div>
    </div></>);
}

function ListingStatus(props) {
    const { listing } = props;

    
    return (listing ? <>Status: <span>{PROCESSSTATUS[listing.processStatus]}</span></> : null);
}