import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Listings from "./pages/Listings.js";
import ToolsPage from "./pages/ToolsPage.js";
import VendorsPage from "./pages/VendorsPage";
import VendorPage from "./pages/VendorPage";
import DocumentProviders from './pages/DocumentProviders';
import Error from "./pages/Error.js";
import ListingDetailPage from "./pages/ListingDetailPage";
import DashboardPage from './components/dashboard-template/DashboardPage'
import Home from './pages/Home'
import HttpLogsPage from "./pages/HttpLogsPage.js";
import ConfigurationsPage from "./pages/ConfigurationsPage.js"

export default function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/listings" component={Listings} />
        <Route exact path="/listing/:id" component={ListingDetailPage} />
        <Route exact path="/error" component={Error} />
        <Route exact path="/tools" component={ToolsPage} />
        <Route exact path="/vendors" component={VendorsPage} />
        <Route exact path="/vendor/:id" component={VendorPage} />
        <Route exact path="/vendor/document-providers/:docType" component={DocumentProviders} />
        <Route exact path="/dashboard" component={DashboardPage} />
        <Route exact path="/logs/http" component={HttpLogsPage} />
        <Route exact path="/configs/features" component={ConfigurationsPage} />
        <Route path="/" component={Home} />
      </Switch>
  </Router>
);
}
