import React from "react";
import { useState, useRef, useEffect  } from "react";
import Transition from '../components/utils/Transition'

const enabledStyle = "inline-flex items-center justify-center rounded leading-5 px-2.5 py-2 bg-white hover:bg-indigo-500 border border-gray-200 text-gray-600 hover:text-white shadow-sm";
const disabledStyle = "inline-flex items-center justify-center rounded leading-5 px-2.5 py-2 bg-white border border-gray-200 text-gray-300";

function Pagination(props) {
    const { pages, onPageClick, onNextClick, onPrevClick, currentPage } = props;

    if (!pages) return null;

    const [currPage] = pages.filter(p => p.page === currentPage);
    const [nextPage] = pages.filter(p => p.page === (currentPage + 1));
    const nextDisabled = !nextPage

    function getFilteredPages(currentPage, pages) {
        const lastPage = pages.length
        let showPageNumArr = [1]; // always show page 1

        if (!showPageNumArr.includes(currentPage)) {
            showPageNumArr.push(currentPage); // always show current page
        }
        if (!showPageNumArr.includes(pages.length)) {
            showPageNumArr.push(lastPage); // always show last page
        }
        if (!showPageNumArr.includes(lastPage - 1)) {
            showPageNumArr.push(lastPage - 1); // always show 2nd to last page (because last page will fetch new page)
        }

        for (let i = 1; i < lastPage; i++) {
            // only show max 6 page numbers
            if (showPageNumArr.length > 5) break;

            // check left of current page
            const left = currentPage - i
            if ((left) > 0 && !showPageNumArr.includes(left)) {
                showPageNumArr.push(left);
            }
            // check right of current page
            const right = currentPage + i
            if ((right) < lastPage && !showPageNumArr.includes(right)) {
                showPageNumArr.push(right);
            }
        }

        // sort the showPages ascending
        showPageNumArr.sort((a, b) => a - b)

        // return filtered pages to render on Pagination component
        return pages.filter((p) => {
            if (p.page && showPageNumArr.includes(p.page)) return true;
            return false;
        });
      }
    
    const filteredPages = getFilteredPages(currentPage, pages)

    return (
      <div className="flex justify-center mt-4">
        <nav className="flex" role="navigation" aria-label="Navigation">
          <PreviousButton disabled={currentPage == 1} onClick={onPrevClick} />
          <ul className="inline-flex text-sm font-medium -space-x-px shadow-sm">
                {filteredPages.map((p, idx) => {
                    const nextPage = filteredPages[idx + 1]
                    let ellipses = false;
                    if (
                        nextPage &&
                        nextPage.page &&
                        nextPage.page - filteredPages[idx].page > 1
                    ) {
                        ellipses = true;
                    }
                    return (
                        <React.Fragment key={idx}>
                            <PageNumber
                                page={p}
                                currentPage={currentPage}
                                onClick={onPageClick}
                            />
                            {ellipses ? (
                                <li>
                                    <span className="flex mt-1 ml-1">...</span>
                                </li>
                            ) : null}
                        </React.Fragment>
                    );
                })}
          </ul>
          <NextButton disabled={nextDisabled} onClick={onNextClick} />
        </nav>
      </div>
    );
}

function NextButton(props) {
    const { disabled, onClick } = props;

    const svg = (<>
        <span className="sr-only">Next</span><wbr /><svg className="h-4 w-4 fill-current" viewBox="0 0 16 16">
            <path d="M6.6 13.4L5.2 12l4-4-4-4 1.4-1.4L12 8z" />
        </svg>
    </>);

    return (<>
        <div className="ml-2">
            <a className={disabled ? disabledStyle : enabledStyle} onClick={onClick} disabled={disabled}>
                {svg}
            </a>

        </div>
    </>);
}

function PreviousButton(props) {
    const { disabled, onClick } = props;

    const enabledStyle = "inline-flex items-center justify-center rounded leading-5 px-2.5 py-2 bg-white hover:bg-indigo-500 border border-gray-200 text-gray-600 hover:text-white shadow-sm";
    const disabledStyle = "inline-flex items-center justify-center rounded leading-5 px-2.5 py-2 bg-white border border-gray-200 text-gray-300";

    const svg = (<>
        <span className="sr-only">Previous</span><wbr />
        <svg className="h-4 w-4 fill-current" viewBox="0 0 16 16">
            <path d="M9.4 13.4l1.4-1.4-4-4 4-4-1.4-1.4L4 8z" />
        </svg>
    </>);

    return (<>
        <div className="mr-2">
            <a href="#0" className={disabled ? disabledStyle : enabledStyle} disabled={disabled} onClick={onClick}>
                {svg}
            </a>
        </div>
    </>);
}

function PageNumber(props) {

    const { page, currentPage, onClick } = props;

    const isCurrentPage = currentPage === page.page;

    function internalClick(e) {
        onClick && onClick(page);
    }

    const spacer = page.page === 1 ? null : 'ml-2'
    if (isCurrentPage) {
        return (<>
            <li>
                <span className={`inline-flex items-center justify-center rounded-l leading-5 px-3.5 py-2 bg-white border border-gray-200 bg-gray-100 text-gray-400 ${spacer}`}>{page.page}</span>
            </li></>);

    }
    else {
        return (<>
            <li>
                <a onClick={internalClick} className={`inline-flex items-center justify-center leading-5 px-3.5 py-2 bg-white hover:bg-indigo-500 border border-gray-200 text-gray-600 hover:text-white ${spacer}`} href="#0">{page.page}</a>
            </li></>);

    }
}

function DropdownClassic(props) {

    const { options, onClick, className } = props;

    

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selected, setSelected] = useState(1);

    const trigger = useRef(null);
    const dropdown = useRef(null);

    // close on click outside
    useEffect(() => {
        const clickHandler = ({ target }) => {
            if (!dropdown.current) return;
            if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
            setDropdownOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!dropdownOpen || keyCode !== 27) return;
            setDropdownOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });

    return (
        <div className="relative inline-flex">
            <button
                ref={trigger}
                className="btn justify-between min-w-44 bg-white border-gray-200 hover:border-gray-300 text-gray-500 hover:text-gray-600"
                aria-label="Select number of items"
                aria-haspopup="true"
                onClick={() => setDropdownOpen(!dropdownOpen)}
                aria-expanded={dropdownOpen}
            >
                <span className="flex items-center">
                    <span>{options[selected].label}</span>
                </span>
                <svg className="flex-shrink-0 ml-1 fill-current text-gray-400" width="11" height="7" viewBox="0 0 11 7">
                    <path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z" />
                </svg>
            </button>
            <Transition
                show={dropdownOpen}
                tag="div"
                className="z-10 absolute top-full left-0 w-full bg-white border border-gray-200 py-1.5 rounded shadow-lg overflow-hidden mt-1"
                enter="transition ease-out duration-100 transform"
                enterStart="opacity-0 -translate-y-2"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-out duration-100"
                leaveStart="opacity-100"
                leaveEnd="opacity-0"
            >
                <div
                    ref={dropdown}
                    className="font-medium text-sm text-gray-600"
                    onFocus={() => setDropdownOpen(true)}
                    onBlur={() => setDropdownOpen(false)}
                >
                    {
                        options.map((option,index) => {
                            return (
                                <button
                                    key={option.id}
                                    tabIndex="0"
                                    className={`flex items-center w-full hover:bg-gray-50 py-1 px-3 cursor-pointer ${option.id === selected && 'text-indigo-500'}`}
                                    onClick={() => { setSelected(index); setDropdownOpen(false); onClick && onClick(option); }}
                                >
                                    <svg className={`flex-shrink-0 mr-2 fill-current text-indigo-500 ${index !== selected && 'invisible'}`} width="12" height="9" viewBox="0 0 12 9">
                                        <path d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z" />
                                    </svg>
                                    <span>{option.label}</span>
                                </button>
                            )
                        })
                    }
                </div>
            </Transition>
        </div>
    );
}

export { Pagination, PageNumber, DropdownClassic, NextButton, PreviousButton }