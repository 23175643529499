import { useCallback, useState, useEffect } from "react";
import axios from "axios";

const CALL_STATE = Object.freeze({
    "None": 0,
    "Waiting": 1
})

export const useApi = (route, args) => {

    const [request, setRequest] = useState(args);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [pages, setPages] = useState([{ page: 1 }]);
    const [isLoading, setIsLoading] = useState(false);

    // Ensures the pagge exists. If not, adds it to the 
    // collection
    function ensurePageExists(pageNo) {
        var page = getPage(pageNo);

        if (!page) {

            page = {
                page: pageNo,
                request: request
            };

            setPages(p => {
                return [...p, page]
            });
        }

        return page;
    }

    // go to next page
    const nextPage = useCallback(function () {
        var lastPage = Math.max(...pages.map(p => p.page));
        if (currentPage == lastPage) {
            return;
        }
        setCurrentPage(p => p + 1);
    });

    // go to prev page
    const prevPage = useCallback(function () {
        if (currentPage === 1) {
            return;
        }
        setCurrentPage(p => p - 1);
    }
    );

    // Jump to a specific page
    const gotoPage = useCallback(function (p) {
        setCurrentPage(p.page);
    });

    // get first page
    function getPage(pageNo) {
        const [result] = pages.filter(p => p.page === pageNo);
        return result;
    }

    const handleRouteSuccess = (r) => {
        // If success, we have data
        if (r.data.isSuccess) {
            console.log(r.data);

            let morePages = false;

            // Handle entity list page 
            if (r.data.entityType) {
                // Set the request but remove properties we don't want to keep in the request (isSuccess, items)
                if ((r.data.currentPartition < r.data.maxPartition) ||
                    (r.data.currentPartition === r.data.maxPartition && r.data.continuationToken)) {
                    morePages = true;
                }
            }
            else if (r.data.continuationToken) {
                morePages = true;
            }

            if (morePages) {
                const nextPage = ensurePageExists(currentPage + 1);
                // Set continuation token
                setPages(p => {
                    return p.map(_p => (currentPage + 1) === _p.page ? { ..._p, request: { ...r.data, isSuccess: undefined, items: undefined } } : _p);
                });
            }

            // Set the data
            setData(r.data.items);

            setIsLoading(false);
        }
        else {
            setError(r.data.error);
            setIsLoading(false);
        }
    }

    const handlRouteError = (e) => {
        console.log(e);
        setError(e);
        setIsLoading(false);
    }

    useEffect(() => {

        // Do nothing if it is loading
        if (isLoading) return;

        // Set the is loading flag
        setIsLoading(true);

        // Ensure the current page an the next page exists
        const page = ensurePageExists(currentPage);

        // Call route to get page data
        const apiRequest = { ...request, ...page.request };
        let r = null;

        try {
            if (args) {
                axios.post(route, apiRequest)
                    .then(handleRouteSuccess)
                    .catch(handlRouteError);
            }
            else {
                axios.get(route)
                    .then(handleRouteSuccess)
                    .catch(handlRouteError);
            }
        }
        catch (error) {
            setError(error);
        }

    }, [currentPage, request.pageSize]);

    return [data, error, nextPage, prevPage, gotoPage, currentPage, pages, isLoading, setRequest];

}