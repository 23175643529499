const LISTING_STATUS = Object.freeze({
    0: "Unknown",
    1: "Active",
    2: "Pending",
    3: "OffMarket",
    4: "Terminate",
    5: "SubscriptionHold",
    6: "Unpublished",
    7: "AwaitingVerification",
    8: "UserDeleted",
    9: "Upsell",
    10: "Confidential",
    11: "Introduction",
    12: "Migrated",
    13: "Draft"
})

const REPORT_STATUS = Object.freeze({
    0: "Unknown",
    1: "VendorSelection",
    2: "Pending",
    3: "Sent To Vendor",
    4: "Vendor Confirmed",
    5: "Document URL Received",
    6: "S3 Download Failed",
    7: "Dataroom upload failed",
    8: "Document uploaded",
    9: "Completed",
    10: "PendingCancellation",
    11: "Cancelled"
});

const REPORT_STATUS_LABEL = Object.freeze({
    0: "Unknown",
    1: "Select Vendor",
    2: "Pending",
    3: "Sent To Vendor",
    4: "Vendor Confirmed",
    5: "Document Received",
    6: "Download Failed.",
    7: "Dataroom Failed",
    8: "Document Uploaded",
    9: "Completed",
    10: "Pending Cancellation",
    11: "Cancelled"
});

const DOC_TYPE = Object.freeze({
    1: "Title",
    2: "Full Phase 1",
    3: "Property Condition",
    4: "Limited Phase 1"
});

const CONTACT_ROLE = Object.freeze({
            1: "Listing Broker",
            2: "Seller Contact",
            3: "Auction Asset Manager",
            4: "Account Executive",
            5: "Contracts Associates",
            6: "Site Contact",
            7: "Billing Contact",
            8: "Due Diligence Team"
});

export 
{
    LISTING_STATUS,
    REPORT_STATUS, 
    REPORT_STATUS_LABEL,
    DOC_TYPE,
    CONTACT_ROLE
}