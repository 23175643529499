import React, { useState } from 'react';

import Sidebar from './sidebar';
import Header from './header';
import WelcomeBanner from './welcome-banner';
import DashboardAvatars from './dashboard-avatars';
import FilterButton from './FilterButton';
import Datepicker from './Datepicker';
import Banner from './Banner';
import { propTypes } from 'react-bootstrap/esm/Image';

function DashboardPage(props) {

  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="flex h-screen overflow-hidden min-w-80">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl">

            {/* Cards */}
            <div className="grid grid-cols-12 gap-6">
            </div>

            {props.children}

          </div>
        </main>

        {/* <Banner /> */}

      </div>
    </div>
  );
}

export default DashboardPage;
