import { DateTime } from "luxon";
import React from "react";

export default function DateTimeFormat(props) {
    const { value, format } = props;

    function formatDate(dt) {
        if (!dt) return "";

        var _local = DateTime.fromISO(dt);

        if (format) {
            return _local.toFormat(format);
        }

        return _local.toLocaleString();
    }

    if (value) {
        return (<> {
            formatDate(value)
        }</>);
    }

    return null;
}