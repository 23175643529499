
function Icon(props) {
    const { value } = props;

    if (value === 1) {
        return (<><svg class="sj st us hn mo ry rl" viewBox="0 0 10 10"><path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"></path></svg></>);
    }
    else if (value === 2) {
        return <InfoIcon />
    }
    else if (value === 3) {
        return <WarningIcon />
    }
    else if (value === 4) {
        return <ErrorIcon />
    }

    return null;
}


function InfoIcon(props) {

    const { minX = 0, minY = 0, width = 18, height = 18, color = 'indigo' } = props;

    return (<svg className={`w-4 h-4 flex-shrink-0 fill-current opacity-80 mt-[3px] mr-3 text-${color}-500`} viewBox={`${minX} ${minY} ${width} ${height}`} >
        <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z" />
    </svg>)
}

function WarningIcon(props) {

    const { minX = 0, minY = 0, width = 18, height = 18, color = 'yellow' } = props;

    return (<svg className="w-4 h-4 flex-shrink-0 fill-current opacity-80 mt-[3px] mr-3" viewBox={`${minX} ${minY} ${width} ${height}`}>
        <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z" />
    </svg>)
}

function ErrorIcon(props) {

    const { minX = 0, minY = 0, width = 18, height = 18, color = 'red' } = props;

    return (<svg className={`"w-4 h-4 flex-shrink-0 fill-current opacity-80 mt-[3px] mr-3 text-${color}-500`} viewBox={`${minX} ${minY} ${width} ${height}`}>
        <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z" />
    </svg>)
}

function Spinner(props) {

    const { type, isSpinning, className } = props;

    if (isSpinning === false) {
        return null;
    }

    if (type === 'balls') {
        let circleCommonClasses = 'h-2.5 w-2.5 bg-current rounded-full';

        return (
            <div className={`${className} flex`}>
                <div className={`${circleCommonClasses} mr-1 animate-bounce`}></div>
                <div className={`${circleCommonClasses} mr-1 animate-bounce200`}></div>
                <div className={`${circleCommonClasses} animate-bounce200`}></div>
            </div>
        );
    }
    else {
        return (<div className="bg-rose-600 ml-1"><svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-blue" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg></div>)
    }
    return null;
};


export { Icon, Spinner }