import React from "react";
import DashboardPage from "../components/dashboard-template/DashboardPage";
import SectionHeader from "../components/dashboard-template/SectionHeader";
import ActivityCard from "../components/ActivityCard";

export default function Listings() {

    return (<>
        <DashboardPage>
            <SectionHeader size="2">Home</SectionHeader>
            <ActivityCard />
        </DashboardPage>
    </>);
}